export default {
  methods: {
    renderErrors(response) {
      let errors = ''
      response.response.data.errors.forEach(x => {
        const objects = Object.keys(x)
        objects.forEach(y => {
          errors += x[y] + '\n'
        })
      })
      this.$modal.error({
        title: response.response.data.message,
        content: errors,
      })
    },
  },
}
